import React from 'react';
import PaymentOptions, {
    PaymentOptionsColumn,
    PaymentOptionIcon,
    PaymentOptionTitle,
    PaymentOptionDescription,
    PaymentOptionImages,
    PaymentOptionButtons,
    PaymentOptionNote,
    PaymentOptionCallout,
    PaymentOptionList,
    PaymentOptionListItem,
    PaymentOptionHeading
} from '../../../components/PaymentOptions/PaymentOptions';
import { Link } from '@latitude/link';
import Section from '@latitude/section';
import SvgInline from '@latitude/svg-inline';
import {
    BREAKPOINT,
  } from '@latitude/core/utils/constants';
import { Strong } from '@/components/Text/Bold';  

import Text from '@/components/Text/Text';

const PaymentOption = () => {
    return (
            <Section
                heading="Payment Options"
                id="payment-options"
                className="section--payment-options"
                css={`
          && {
            @media (min-width: ${BREAKPOINT.LG}) {
              padding-bottom: 72px;
            }
          }
        `}
            >
                <PaymentOptions
                    data={[
                        {
                            id: 'latitude-app',
                            title: 'Latitude App',
                            titleHtml: 'Latitude App',
                            content: (
                                <React.Fragment>
                                    <PaymentOptionsColumn>
                                        <PaymentOptionIcon>
                                            <SvgInline name="payment-option-latitude-app" />
                                        </PaymentOptionIcon>
                                        <PaymentOptionTitle>Latitude App</PaymentOptionTitle>
                                        <PaymentOptionDescription>
                                            Make repayments in just a few taps with our mobile app.
                                        </PaymentOptionDescription>
                                        <PaymentOptionImages>
                                            <Link
                                                href="https://itunes.apple.com/app/apple-store/id1373059866?pt=118165058&ct=InfPayOpt0319&mt=8"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="d-inline"
                                                data-trackid="payment-options-itunes-app-link"
                                                trackEventData={{
                                                    label: ''
                                                }}
                                            >
                                                <img
                                                    src={
                                                        require('../../../images/badge-app-store.svg')
                                                            .default
                                                    }
                                                    height="48px"
                                                    alt="app store"
                                                />
                                            </Link>
                                            <Link
                                                href="https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp&referrer=utm_source%3DInfPayOpt0319"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="d-inline"
                                                data-trackid="payment-options-googleplay-app-link"
                                                trackEventData={{
                                                    label: ''
                                                }}
                                            >
                                                <img
                                                    src={
                                                        require('../../../images/badge-google-play.svg')
                                                            .default
                                                    }
                                                    height="48px"
                                                    alt="play store"
                                                />
                                            </Link>
                                        </PaymentOptionImages>
                                    </PaymentOptionsColumn>
                                    <PaymentOptionsColumn>
                                        <PaymentOptionList>
                                            <PaymentOptionListItem>
                                                <Strong>Get the app</Strong>
                                                <Text fontSize="14px">
                                                    Simply download the Latitude App from the App Store
                                                    or Google Play
                                                </Text>
                                            </PaymentOptionListItem>
                                            <PaymentOptionListItem>
                                                <Strong>Create a Latitude ID</Strong>
                                                <Text fontSize="14px">
                                                    Open the app and create your Latitude ID (this is
                                                    different to your Latitude Service Centre login).
                                                </Text>
                                            </PaymentOptionListItem>
                                            <PaymentOptionListItem>
                                                <Strong>Link your card</Strong>
                                                <Text fontSize="14px">
                                                    Once your credit cards are linked, you’ll be able to
                                                    make payments via bank transfer with your BSB and
                                                    Account Number (Australia only).
                                                </Text>
                                            </PaymentOptionListItem>
                                        </PaymentOptionList>
                                    </PaymentOptionsColumn>
                                    <PaymentOptionsColumn>
                                        <PaymentOptionNote>
                                            <Text fontSize="14px">
                                                <Strong>Note:</Strong> Please be aware that bank
                                                transfer payments can take three business days to
                                                clear and you can only make one payment to your card
                                                account every five days.
                                            </Text>
                                        </PaymentOptionNote>
                                        <PaymentOptionCallout>
                                            <Strong>No Fees</Strong>
                                            <Text fontSize="14px">
                                                We do not charge a fee for payments made via direct
                                                bank transfer on the Latitude App.
                                            </Text>
                                        </PaymentOptionCallout>
                                    </PaymentOptionsColumn>
                                </React.Fragment>
                            )
                        },
                        {
                            id: 'online-service-centre',
                            title: 'Latitude Service Centre',
                            titleHtml: 'Latitude Service Centre',
                            content: (
                                <React.Fragment>
                                    <PaymentOptionsColumn>
                                        <PaymentOptionIcon>
                                            <SvgInline name="payment-option-online" />
                                        </PaymentOptionIcon>
                                        <PaymentOptionTitle>
                                            Latitude Service Centre
                                        </PaymentOptionTitle>
                                        <PaymentOptionDescription>
                                            One of the quickest &amp; easiest way to make
                                            repayments.
                                        </PaymentOptionDescription>
                                        <PaymentOptionButtons css="justify-content:center;">
                                            <Link
                                                target="_blank"
                                                button="secondary"
                                                href="https://servicecentre.latitudefinancial.com.au/login"
                                                data-trackid="payment-options-service-center-login-link"
                                                css="margin: 0 8px; flex: 1 1 50%;"
                                                disableDefaultEventTracking
                                            >
                                                Login
                                            </Link>
                                            <Link
                                                target="_blank"
                                                button="tertiary"
                                                href="https://servicecentre.latitudefinancial.com.au/register"
                                                data-trackid="payment-options-service-center-register-link"
                                                css="margin: 0 8px; flex: 1 1 50%;"
                                                disableDefaultEventTracking
                                            >
                                                Register
                                            </Link>
                                        </PaymentOptionButtons>
                                    </PaymentOptionsColumn>
                                    <PaymentOptionsColumn>
                                        <PaymentOptionHeading>
                                            To make a payment
                                        </PaymentOptionHeading>
                                        <PaymentOptionList>
                                            <PaymentOptionListItem>
                                                <Strong>Login to the Latitude Service Centre</Strong>
                                            </PaymentOptionListItem>
                                            <PaymentOptionListItem>
                                                <Strong>Click ‘Pay My Account’</Strong>
                                                <Text fontSize="14px">
                                                    Then enter your bank details.
                                                </Text>
                                            </PaymentOptionListItem>
                                        </PaymentOptionList>
                                        <PaymentOptionHeading>Registering</PaymentOptionHeading>
                                        <Text fontSize="14px">
                                            Registering is easy and only takes a few minutes, just
                                            make sure you have your card or 16-digit account number
                                            on hand.
                                        </Text>
                                    </PaymentOptionsColumn>
                                    <PaymentOptionsColumn>
                                        <Text fontSize="14px">
                                            <Strong>Note:</Strong> Please be aware that payments can
                                            take 3 to 5 business days to clear.
                                        </Text>
                                        <PaymentOptionCallout>
                                            <Strong>No Fees</Strong>
                                            <Text fontSize="14px">
                                                We do not charge a fee for payments made via the
                                                Latitude Service Centre.
                                            </Text>
                                        </PaymentOptionCallout>
                                    </PaymentOptionsColumn>
                                </React.Fragment>
                            )
                        },
                        {
                            id: 'bpay',
                            title: 'BPAY',
                            titleHtml:
                                'BPAY<sup style="font-size: 0.75em; top: -0.5em;">&reg;</sup>',
                            content: (
                                <React.Fragment>
                                    <PaymentOptionsColumn>
                                        <PaymentOptionIcon>
                                            <SvgInline name="payment-option-bpay" />
                                        </PaymentOptionIcon>
                                        <PaymentOptionTitle>
                                            BPAY<sup>®</sup>
                                        </PaymentOptionTitle>
                                        <PaymentOptionDescription>
                                            Available 24 hours a day, seven days a week.
                                        </PaymentOptionDescription>
                                    </PaymentOptionsColumn>
                                    <PaymentOptionsColumn>
                                        <PaymentOptionHeading>
                                            To make a payment
                                        </PaymentOptionHeading>
                                        <PaymentOptionList>
                                            <PaymentOptionListItem>
                                                <Strong>Quote the Biller code</Strong>
                                                <Text fontSize="14px">
                                                Latitude Low Rate Mastercard Biller Code (8573)
                                                </Text>
                                            </PaymentOptionListItem>
                                            <PaymentOptionListItem>
                                                <Strong>Your account number</Strong>
                                            </PaymentOptionListItem>
                                            <PaymentOptionListItem>
                                                <Strong>The amount you wish to pay</Strong>
                                            </PaymentOptionListItem>
                                        </PaymentOptionList>
                                    </PaymentOptionsColumn>
                                    <PaymentOptionsColumn>
                                        <Text fontSize="14px">
                                            <Strong>Note:</Strong> If you’re paying by BPAY on your
                                            due date, you must complete the transaction before your
                                            bank’s cut off time, otherwise the transaction may be
                                            processed on the following day. Some BPAY payments can
                                            take up to three days to appear on your account.
                                        </Text>
                                        <PaymentOptionCallout>
                                            <Strong>Fees</Strong>
                                            <Text fontSize="14px">
                                                A payment handling fee of $1.95 applies for all
                                                payments made via BPAY<sup>&reg;</sup> to pay your
                                                account.
                                            </Text>
                                        </PaymentOptionCallout>
                                    </PaymentOptionsColumn>
                                </React.Fragment>
                            )
                        },
                        {
                            id: 'direct-debit',
                            title: 'Direct Debit',
                            titleHtml: 'Direct Debit',
                            content: (
                                <>
                                    <PaymentOptionsColumn>
                                        <PaymentOptionIcon>
                                            <SvgInline name="payment-option-direct-debit" />
                                        </PaymentOptionIcon>
                                        <PaymentOptionTitle>Direct Debit</PaymentOptionTitle>
                                        <PaymentOptionDescription>
                                            Easily make recurring repayments.
                                        </PaymentOptionDescription>
                                    </PaymentOptionsColumn>
                                    <PaymentOptionsColumn longColumn>
                                        <Text>
                                            The easiest (and quickest) way to pay off your credit
                                            card is with a <strong> monthly direct debit</strong>.
                                        </Text>
                                        <br />
                                        <Text>
                                            You can choose whether to pay a set amount, the monthly
                                            minimum or the full closing balance, and on your due
                                            date each month your payment will automatically happen!
                                        </Text>
                                        <br />
                                        <Text>
                                            <Link href="https://latitudefs.zendesk.com/hc/en-au/articles/6027991389329-Direct-Debit-Cards">
                                                Click here to find out more about setting up a direct
                                                debit.
                                            </Link>
                                        </Text>
                                    </PaymentOptionsColumn>
                                </>
                            )
                        }
                    ]}
                    // tabContentMinHeight="385px"
                    css={`
            && {
              .payment-options-tabs__content {
                padding: 32px;
              }
            }
          `}
                />
            </Section>
    )
};

export default PaymentOption;